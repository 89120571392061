import { useTranslation } from '@pancakeswap/localization'
import { CameraIcon, Flex, SellIcon, Text } from '@pancakeswap/uikit'
import { RareTags } from 'state/nftMarket/types'
import FirstEdition from 'src/assets/firstedition.svg'

const RareTag: React.FC<React.PropsWithChildren<{ tagType: RareTags }>> = ({ tagType }) => {
  const { t } = useTranslation()

  const FirstEditionTag = () => {
    // use this emoji for a place holder for now
    return (
      <Flex justifyContent="center">
        <FirstEdition />
      </Flex>
    )
  }

  const ForSaleTag = () => {
    return (
      <Flex justifyContent="center">
        <SellIcon height="12px" color="failure" />
        <Text color="failure" fontSize="14px">
          {t('For sale')}
        </Text>
      </Flex>
    )
  }

  const ProfilePicTag = () => {
    return (
      <Flex justifyContent="center">
        <CameraIcon height="12px" color="textSubtle" />
        <Text color="textSubtle" fontSize="14px">
          {t('Profile')}
        </Text>
      </Flex>
    )
  }

  const tagsConfig = {
    [RareTags.FIRSTEDITION]: FirstEditionTag(),
    //  [NftLocation.PROFILE]: ProfilePicTag(),
    //  [NftLocation.FORSALE]: ForSaleTag(),
  }

  return tagsConfig[tagType]
}

export default RareTag
